<template>
  <div class="py-4" style="background: linear-gradient(270deg, #9D4EFF 0%, #782AF4 100%);">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-xl-12 col-lg-12 col-md-12 col-12">
          <div class="d-md-flex align-items-center justify-content-between text-white">
            <div class="d-md-flex align-items-center text-lg-start text-center ">
              <div class="mt-3 mt-md-0">
                <h1 class="mb-0 fw-bold me-3 text-white">Thông báo</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="py-4">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <ul class="list-group list-group-flush" v-if="notifications.data && notifications.data.length">
                <li :class="notification.is_read === 1 ? 'list-group-item' : 'list-group-item bg-light'" :key="'notification' + index" v-for="(notification, index) in notifications.data">
                  <div class="row">
                    <div class="col">
                      <a href="javascript:void(0)" class="text-body" v-on:click="readNotification(notification)">
                        <div class="d-flex">
                          <img
                              :src="notification.sender && notification.sender.thumbnail ? notification.sender.thumbnail.link : '/assets/images/avatar/avatar-1.jpg'"
                              alt=""
                              class="avatar-md rounded-circle"
                          />
                          <div class="ms-3">
                            <h5 class="fw-bold mb-1">{{ notification.sender ? notification.sender.name : '' }}</h5>
                            <p class="mb-3">
                              {{ notification.content }}
                            </p>
                            <span class="fs-6 text-muted">
													<span
                          ><span
                              class="fe fe-thumbs-up text-success me-1"
                          ></span
                          >{{ timeago(notification.created_at) }}</span
                          >
												</span>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div class="col-auto text-center me-2" v-if="!notification.is_read">
                      <a
                          href="javascript:void(0)"
                          v-on:click="markAsRead(notification)"
                          class="badge-dot bg-info"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Đánh dấu đã đọc"
                      >
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
              <div>
                <v-pagination
                    v-if="notifications.paginatorInfo.lastPage > 1"
                    v-model="notifications.paginatorInfo.currentPage"
                    :pages="notifications.paginatorInfo.lastPage"
                    :range-size="1"
                    active-color="#DCEDFF"
                    @update:modelValue="loadNotifications"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import ApiService from "@/core/services/api.service";
import {timeago} from "@/core/services/utils.service";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default {
  name: "notification",
  components: {
    VPagination
  },
  data() {
    return {
      unread: 0,
      notifications: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          total: 0,
          lastPage: 0
        }
      }
    }
  },
  methods: {
    loadNotifications() {
      let query = `query($page: Int) {
        me {
          id
          unread
          notifications(first:5, page: $page, orderBy: [{column: "id", order: DESC}]) {
            data {
              code
              content
              extra
              is_read
              created_at
              sender {
                name
                thumbnail {
                  link
                }
              }
            }
            paginatorInfo {
              currentPage
              total
              lastPage
            }
          }
        }
      }`;

      ApiService.graphql(query, {page: this.notifications.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.me) {
              this.unread = data.data.me.unread;
              this.notifications = data.data.me.notifications;
            }
          });
    },
    markAsRead(notification) {
      let query = `mutation($code: String!) {
        markAsRead(code: $code)
      }`;

      ApiService.graphql(query, {code: notification.code})
          .then(({data}) => {
            if (data.data && data.data.markAsRead) {
              this.loadNotifications();
            } else {
              notification.is_read = 0;
            }
          });
    },
    markAsReadAll() {
      let query = `mutation {
        markAsReadAll
      }`;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.markAsReadAll) {
              this.loadNotifications();
            }
          });
    },
    readNotification(notification) {
      if (!notification.is_read) {
        notification.is_read = 1;
        this.markAsRead(notification);
      }
      if (notification.extra) {
        try {
          let extra = JSON.parse(notification.extra);
          if (extra.router && extra.router.name && extra.router.params) {
            this.$router.push({name: extra.router.name, params: extra.router.params});
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
    timeago(x) {
      return timeago(x);
    }
  },
  mounted() {
    this.loadNotifications();
  },
}
</script>
